import './bootstrap';


import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';
document.addEventListener('DOMContentLoaded', () => {
    loadInfiniteScroll()
    let current_route_name = document.querySelector('[name="current_route_name"]').getAttribute('value');
    if(current_route_name === 'front.post'){
        function callLikeTrigger(likeTrigger, postId)
        {
            if(likeTrigger){

                let jsonWithLikesForPosts = localStorage.getItem('jsonWithLikesForPosts');
                if(jsonWithLikesForPosts && jsonWithLikesForPosts.includes(postId))  likeTrigger.classList.add('is--active')

                likeTrigger.addEventListener('click', function (){


                    let jsonWithLikesForPosts = localStorage.getItem('jsonWithLikesForPosts');
                    if(!jsonWithLikesForPosts){
                        jsonWithLikesForPosts = [];
                    }else{
                        jsonWithLikesForPosts = JSON.parse(jsonWithLikesForPosts);
                    }

                    if(jsonWithLikesForPosts && !jsonWithLikesForPosts.includes(postId)){

                        fetch(document.querySelector("[name='link_on_rate_post']").getAttribute('value'), {
                            method:"POST",
                            body: JSON.stringify({'postId':postId}),
                        }).then(function (r){
                            return r.json();
                        }).then(function (obj){
                            if(obj.status === "success"){
                                jsonWithLikesForPosts.push(postId)
                                jsonWithLikesForPosts = JSON.stringify(jsonWithLikesForPosts)
                                localStorage.setItem('jsonWithLikesForPosts', jsonWithLikesForPosts);
                                likeTrigger.classList.add('is--active')
                                likeTrigger.querySelector("span").innerText = obj.likes;
                            }else{
                                console.log('error', obj)
                            }
                        }).catch(function (e){
                            console.log('error', e)
                        })
                    }
                })
            }
        }
        callLikeTrigger(document.querySelector(".like-trigger"), document.querySelector("[name='post_id']").getAttribute('value'))
        var fetchingNextArticle = false;
        var observerForHistory;
        if (document.querySelector(".article-wrapper")) {
            let htmlPostTitle = document.querySelector('title').innerText
            if(htmlPostTitle) document.querySelector('.post-content .dp_info-content').setAttribute('data-title-html', htmlPostTitle)

            observerForHistory = new IntersectionObserver((entries, observer) => {

                entries.forEach(entry => {
                    if (entry.isIntersecting && window.location.href!==entry.target.getAttribute('data-io-article-url')) {

                        let title = entry.target.getAttribute('data-title-html')
                        if(title) document.title = title

                        window.history.pushState({}, "", entry.target.getAttribute('data-io-article-url'));
                    }
                });
            });
            observerForHistory.observe(document.querySelector('.post-content .dp_info-content'));


            let throttleTimer;
            const throttle = (callback, time) => {
                if (throttleTimer) return;
                throttleTimer = true;
                setTimeout(() => {
                    callback();
                    throttleTimer = false;
                }, time);
            }
            window.addEventListener("scroll", () => {
                throttle(handleScrollAnimation, 250);
            }, {passive: true});

        }

        function handleScrollAnimation()
        {
            //(the number of pixels by which the document is currently scrolled verticall) > (Height of container which contains all articles (.article-wrapper)) - (half of last article (.post-content:last-child))
            let moreThanHalfOfLastArticle = (window.scrollY || window.pageYOffSet) > document.querySelector(".article-wrapper").clientHeight - (document.querySelector(".post-content:last-child").clientHeight/2)
            if(moreThanHalfOfLastArticle && !fetchingNextArticle) {
                fetchingNextArticle = true;
                let current_id = Array.from(document.querySelectorAll('div.post-content')).pop().getAttribute('id')

                let locale = "/" + document.querySelector("html").getAttribute("lang");
                fetch(locale + '/post-prev-content/' + current_id + "?lazy=true").then(function (r) {
                    return r.text()
                }).then(function (html) {
                    let el = document.createElement('html');
                    el.innerHTML = html
                    let title = el.querySelector("title").innerText
                    let postContent = el.querySelector('.post-content');
                    postContent.querySelector(".dp_info-content").setAttribute('data-title-html', title)
                    document.querySelector("#MIXADV_11570").removeAttribute("id")
                    document.querySelector('.post-content:last-child').after(postContent)
                    document.querySelector('.post-content:last-child').classList.add('mt-4')
                    appendInInfScroll()
                    observerForHistory.observe(document.querySelector('.post-content:last-child .dp_info-content'));
                }).catch(function (e) {
                    console.log(e)
                }).finally(function () {
                    fetchingNextArticle = false;
                })
            }

        }

        function appendInInfScroll()
        {
            imageLazyLoading(document.querySelectorAll('img[src="#"][loading=lazy]'))
            loadJsForFbOembed()
            loadJsForInstaOembed();
            loadSliderInPost()
            loadLiteYoutube();
            callShareTrigger(document.querySelectorAll('.post-content:last-child .share-trigger'))
            callLikeTrigger(document.querySelector(".post-content:last-child .like-trigger"), Array.from(document.querySelectorAll('div.post-content')).pop().getAttribute('id'))
            widgetMgid()
            firstAd()
            secondAd()
            thirdAdd()
        }
    }
    function widgetMgid()
    {
        (function(w,q){w[q]=w[q]||[];w[q].push(["_mgc.load"])})(window,"_mgq");
    }
    function firstAd()
    {
        (function(){var date=new Date();try{date=new Date(date.toLocaleString('en-US',{timeZone:'Europe/Kiev'}));}catch (e){}var ext=[(date.getFullYear()).toString(),('0'+(date.getMonth()+1).toString()).substr(-2),('0'+(date.getDate()).toString()).substr(-2)].join('');var tag=(function(){var informers=document.getElementsByClassName('r100277'),len=informers.length;return len?informers[len-1]:null;})(),idn=(function(){var i,num,idn='',chars="abcdefghiklmnopqrstuvwxyz",len=Math.floor((Math.random()*2)+4);for (i=0;i<len;i++){num=Math.floor(Math.random()*chars.length);idn +=chars.substring(num,num+1);}return idn;})();var container=document.createElement('div');container.id=idn;tag.appendChild(container);var script=document.createElement('script');script.className='s100277';script.src='https://mediabrama.com/shop/j/100277/?v=1?date='+ ext;script.charset='utf-8';script.dataset.idn=idn;tag.parentNode.insertBefore(script,tag);})();
    }
    function secondAd()
    {
        (function(){var date=new Date();try{date=new Date(date.toLocaleString('en-US',{timeZone:'Europe/Kiev'}));}catch (e){}var ext=[(date.getFullYear()).toString(),('0'+(date.getMonth()+1).toString()).substr(-2),('0'+(date.getDate()).toString()).substr(-2)].join('');var tag=(function(){var informers=document.getElementsByClassName('r100276'),len=informers.length;return len?informers[len-1]:null;})(),idn=(function(){var i,num,idn='',chars="abcdefghiklmnopqrstuvwxyz",len=Math.floor((Math.random()*2)+4);for (i=0;i<len;i++){num=Math.floor(Math.random()*chars.length);idn +=chars.substring(num,num+1);}return idn;})();var container=document.createElement('div');container.id=idn;tag.appendChild(container);var script=document.createElement('script');script.className='s100276';script.src='https://mediabrama.com/shop/j/100276/?v=1?date='+ ext;script.charset='utf-8';script.dataset.idn=idn;tag.parentNode.insertBefore(script,tag);})();
    }

    function thirdAdd()
    {
        const thirdAddScript = document.createElement('script');
        thirdAddScript.setAttribute('src','https://m.mixadvert.com/show/?id=11570');
        thirdAddScript.toggleAttribute('async');
        thirdAddScript.setAttribute('type', "text/javascript");
        document.querySelector('.post-content:last-child').appendChild(thirdAddScript)

    }


    function loadSliderInPost()
    {
        var swiper = new Swiper(".post-content:last-child .default-carousel", {
            modules: [Navigation, Pagination],
            loop: true,
            pagination: {
                el: ".post-content:last-child .swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".post-content:last-child .swiper-button-next",
                prevEl: ".post-content:last-child .swiper-button-prev",
            },
        });
    }
    loadSliderInPost();
    loadLiteYoutube();
    document.querySelectorAll('.seo__opener').forEach(function (item) {
        item.addEventListener('click', function (e) {
            let node = e.target
            let siblingsArr = siblings(node, 'seo__text')
            siblingsArr.forEach(function (item2) {
                if (item2.classList.contains('--open')) {
                    window.scrollTo(
                        {top: document.querySelector(".seo").offsetTop - (window.innerHeight / 3 * 2), behavior: 'smooth'});
                }
                item2.classList.toggle('--open')
            })
            node.style.display = 'none'
            siblingsArr = siblings(node, 'seo__opener')
            if (siblingsArr && siblingsArr[0])
                siblingsArr[0].style.display = 'block'
        })
    })








    function siblings(node, className) {
        var sibs = [];
        let elem = node.parentNode.firstChild;
        do {
            if (elem.nodeType === 3) continue; // text node
            if ((!className || (elem.classList && elem.classList.contains(className))) && !elem.isEqualNode(node)) sibs.push(elem);
        } while (elem = elem.nextSibling)
        return sibs;

    }
    imageLazyLoading(document.querySelectorAll('img[src="#"][loading=lazy]'))


    // checker
    const useItemChecker = (els, onClickOutside) => {
        const checkBodyClick = (e) => {
            let currentEl = e.target;

            while (currentEl) {
                if (els.includes(currentEl)) break;
                currentEl = currentEl.parentNode
            }

            if (!currentEl) {
                onClickOutside()
                removeBodyChecker()
            }
        }
        function setBodyChecker  ()  {
            document.documentElement.addEventListener('click', checkBodyClick)
        }
        function removeBodyChecker ()  {
            document.documentElement.removeEventListener('click', checkBodyClick)
        }
        return {setBodyChecker, removeBodyChecker}
    }

    // change theme by clicking a button
    const toggleDarkThemeBtn = document.querySelectorAll('.toggle-dark-theme');

    const enableDarkMode = () => {
        localStorage.theme = 'dark'

        document.documentElement.classList.add('dark');
    };

    const disableDarkMode = () => {
        localStorage.theme = 'light'

        document.documentElement.classList.remove('dark');
    };

    if (toggleDarkThemeBtn) {
        toggleDarkThemeBtn.forEach(item => {
            item.addEventListener('click', (event) => {
                event.preventDefault()

                if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
                    disableDarkMode();
                } else {
                    enableDarkMode();
                }
            });
        })
    }

    // vh
    function vh(percent) {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (percent * h) / 100;
    }

    // cookies
    /*const cookies = document.querySelector('.cookies')
    const cookiesClose = document.querySelector('.js-cookies-close')

    if (cookies && cookiesClose) {
        if (localStorage.cookiesAlert === 'false' || (!('cookiesAlert' in localStorage))) {
            cookies.classList.add('is--active')
        } else {
            cookies.classList.remove('is--active')
        }

        cookiesClose.addEventListener('click', (event) => {
            event.preventDefault()

            if (cookies.classList.contains('is--active')) {
                cookies.classList.remove('is--active')

                localStorage.cookiesAlert = 'true'
            }
        })
    }*/

    // hamburger
    const menu = document.getElementById('menu')
    const hamburgerTrigger = document.getElementById('hamburger-trigger')

    if (menu && hamburgerTrigger) {
        hamburgerTrigger.addEventListener('click', (event) => {
            event.preventDefault()

            if (menu.classList.contains('is--active')) {
                hamburgerTrigger.classList.remove('is--active')
                menu.classList.remove('is--active')
                document.body.classList.remove('scroll-disabled')

                menu.style.removeProperty('top')
                menu.style.removeProperty('max-height')
                menu.style.removeProperty('height')
            } else {
                hamburgerTrigger.classList.add('is--active')
                menu.classList.add('is--active')
                document.body.classList.add('scroll-disabled')

                if (window.innerWidth >= 1280) {
                    let offsetTop = (document.querySelector('.header-fixed-inner').getBoundingClientRect().top >= 0) ? document.querySelector('.header-fixed-inner').getBoundingClientRect().top : 0
                    menu.style.top = Number(56) + offsetTop + 'px'
                    menu.style.maxHeight = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                    menu.style.height = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                }

                if (search && searchTrigger) {
                    if (search.classList.contains('is--active')) {
                        searchTrigger.classList.remove('is--active')
                        search.classList.remove('is--active')
                    }
                }
            }
        })

        menu.addEventListener('click', (event) => {
            if (event.target.classList.contains('menu') || event.target.classList.contains('container')) {
                event.preventDefault()

                if (menu.classList.contains('is--active')) {
                    hamburgerTrigger.classList.remove('is--active')
                    menu.classList.remove('is--active')
                    document.body.classList.remove('scroll-disabled')

                    menu.style.removeProperty('top')
                    menu.style.removeProperty('max-height')
                    menu.style.removeProperty('height')
                }
            }
        })
    }

    // search
    const search = document.getElementById('search')
    const searchTrigger = document.getElementById('search-trigger')

    if (search && searchTrigger) {
        searchTrigger.addEventListener('click', (event) => {
            event.preventDefault()

            if (search.classList.contains('is--active')) {
                searchTrigger.classList.remove('is--active')
                search.classList.remove('is--active')
                document.body.classList.remove('scroll-disabled')

                search.style.removeProperty('top')
                search.style.removeProperty('max-height')
                search.style.removeProperty('height')
            } else {
                searchTrigger.classList.add('is--active')
                search.classList.add('is--active')
                document.body.classList.add('scroll-disabled')

                if (window.innerWidth >= 1280) {
                    let offsetTop = (document.querySelector('.header-fixed-inner').getBoundingClientRect().top >= 0) ? document.querySelector('.header-fixed-inner').getBoundingClientRect().top : 0
                    search.style.top = Number(56) + offsetTop + 'px'
                    search.style.maxHeight = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                    search.style.height = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                }

                if (menu && hamburgerTrigger) {
                    if (menu.classList.contains('is--active')) {
                        hamburgerTrigger.classList.remove('is--active')
                        menu.classList.remove('is--active')
                    }
                }
            }
        })

        search.addEventListener('click', (event) => {
            if (event.target.classList.contains('search') || event.target.classList.contains('container')) {
                event.preventDefault()

                if (search.classList.contains('is--active')) {
                    searchTrigger.classList.remove('is--active')
                    search.classList.remove('is--active')
                    document.body.classList.remove('scroll-disabled')

                    search.style.removeProperty('top')
                    search.style.removeProperty('max-height')
                    search.style.removeProperty('height')
                }
            }
        })
    }

    // like
    // const likeTrigger = document.querySelectorAll('.like-trigger')
    //
    // if (likeTrigger) {
    //     likeTrigger.forEach(item => {
    //         item.addEventListener('click', (event) => {
    //             event.preventDefault()
    //
    //             if (item.classList.contains('is--active')) {
    //                 item.classList.remove('is--active')
    //             } else {
    //                 item.classList.add('is--active')
    //             }
    //         })
    //     })
    // }

    function callShareTrigger(shareTrigger)
    {

        if (shareTrigger) {
            shareTrigger.forEach(item => {
                const close = () => {
                    const parent = item.parentNode

                    parent.classList.remove('is--active')
                }
                const itemChecker = useItemChecker([item.parentNode], close)

                item.addEventListener('click', (event) => {
                    event.preventDefault()

                    const parent = item.parentNode

                    if (parent.classList.contains('is--active')) {
                        close()
                    } else {
                        if(item.parentNode && item.parentNode.parentNode && item.parentNode.parentNode.parentNode){
                            item.parentNode.parentNode.parentNode.style.contentVisibility = "visible";
                        }
                        parent.classList.add('is--active')
                        itemChecker.setBodyChecker()
                    }
                })
            })
        }
    }
    callShareTrigger(document.querySelectorAll('.share-trigger'))
    // scroll
    let prevScrollpos = window.pageYOffset

    function headerScrollUp() {
        if (window.pageYOffset >= (document.querySelector('.header').getBoundingClientRect().top) && window.pageYOffset <= (document.querySelector('.header').getBoundingClientRect().height) && getComputedStyle(document.querySelector('.header-fixed-inner')).position == 'relative') {
            document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, 0px, 0px)'
            document.querySelector('.header-fixed-inner').style.position = 'relative'
        } else {
            if (window.pageYOffset <= (document.querySelector('.header-top').getBoundingClientRect().height)) {
                document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, 0px, 0px)'
                document.querySelector('.header-fixed-inner').style.position = 'relative'
            } else {
                document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, 0px, 0px)'
                document.querySelector('.header-fixed-inner').style.position = 'fixed'
            }
        }
    }

    function headerScrollDown() {
        if (window.pageYOffset >= (document.querySelector('.header').getBoundingClientRect().height)) {
            if (getComputedStyle(document.querySelector('.header-fixed-inner')).position == 'relative') {
                document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, -100%, 0px)'

                setTimeout(() => {
                    document.querySelector('.header-fixed-inner').style.position = 'fixed'
                }, 150)
            } else {
                document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, -100%, 0px)'
            }
        } else {
            document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, 0px, 0px)'
            document.querySelector('.header-fixed-inner').style.position = 'relative'
        }
    }

    window.addEventListener('scroll', () => {
        let currentScrollPos = window.pageYOffset;

        if (prevScrollpos > currentScrollPos || prevScrollpos <= 0) { // if up
            if (!document.body.classList.contains('scroll-disabled')) {
                if (document.querySelector('.header-fixed-inner')) {
                    headerScrollUp()
                }
            }
        } else { // if down
            if (!document.body.classList.contains('scroll-disabled')) {
                if (document.querySelector('.header-fixed-inner')) {
                    headerScrollDown()
                }
            }
        }

        prevScrollpos = currentScrollPos;
    })

    window.addEventListener('resize', () => {
        if (menu) {
            if (menu.classList.contains('is--active')) {
                if (window.innerWidth >= 1280) {
                    let offsetTop = (document.querySelector('.header-fixed-inner').getBoundingClientRect().top >= 0) ? document.querySelector('.header-fixed-inner').getBoundingClientRect().top : 0
                    menu.style.top = Number(56) + offsetTop + 'px'
                    menu.style.maxHeight = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                    menu.style.height = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                } else {
                    menu.style.removeProperty('top')
                    menu.style.removeProperty('max-height')
                    menu.style.removeProperty('height')
                }
            }
        }

        if (search) {
            if (search.classList.contains('is--active')) {
                if (window.innerWidth >= 1280) {
                    let offsetTop = (document.querySelector('.header-fixed-inner').getBoundingClientRect().top >= 0) ? document.querySelector('.header-fixed-inner').getBoundingClientRect().top : 0
                    search.style.top = Number(56) + offsetTop + 'px'
                    search.style.maxHeight = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                    search.style.height = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                } else {
                    search.style.removeProperty('top')
                    search.style.removeProperty('max-height')
                    search.style.removeProperty('height')
                }
            }
        }
    })
});

window.onload = function(e){
    loadJsForFbOembed();
}
/*
add <script async defer src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"></script> if it does not exist and div with class fb-post exists
 */
function loadJsForFbOembed()
{
    try{
        if(!document.querySelector("#fb-post-js-script") && document.querySelector(".fb-post")){
            let script = document.createElement('script');
            script.toggleAttribute("async", true);
            script.toggleAttribute("defer", true);
            script.setAttribute("src", "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2");
            script.setAttribute('id', "fb-post-js-script")
            document.head.appendChild(script);
            script.onload = function (){
                window.FB.XFBML.parse();
            }
        }else if(document.querySelector("#fb-post-js-script") && document.querySelector(".fb-post")){
            window.FB.XFBML.parse();
        }
    }catch (e){
        console.log(e)
    }

}

function loadJsForInstaOembed()
{
    try{
        if(!document.querySelector("#insta-post-js-script") && document.querySelector(".instagram-media ")) {
            let script = document.createElement('script');
            script.setAttribute("src", "https://www.instagram.com/static/bundles/metro/EmbedSDK.js/33cd2c5d5d59.js");
            script.setAttribute("id", "insta-post-js-script");
            document.head.appendChild(script);
        }

    }catch (e){
        console.log(e)
    }

}
function imageLazyLoading(arr)
{
    if ("IntersectionObserver" in window) {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        }


        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const lazyImg = entry.target
                    makeImgLazy(lazyImg)
                    observer.unobserve(lazyImg)
                }
            })
        }, options)

        arr.forEach(i => {
            observer.observe(i)
        })

    }else{
        arr.forEach(i => {
            makeImgLazy(i)
        })
    }
}

function makeImgLazy(lazyImg)
{

    let picture = lazyImg.parentElement
    if(picture && picture.tagName && picture.tagName === "PICTURE"){
        let sources =  picture.querySelectorAll("source");
        sources.forEach(function (source, index){
            if(sources[index].dataset.srcset)
                sources[index].srcset = sources[index].dataset.srcset
        })
    }

    lazyImg.src = lazyImg.dataset.src
}
function loadLiteYoutube()
{
    if(document.querySelector("lite-youtube")  && !document.querySelector("#lite-youtube-embed")){
        let link1 = document.createElement('link');
        link1.setAttribute('rel', "stylesheet");
        link1.setAttribute('href', "/lite-youtube-embed/src/lite-yt-embed.css");
        document.querySelector('head').appendChild(link1);

        let script1 = document.createElement('script')
        script1.src = "/lite-youtube-embed/src/lite-yt-embed.js"
        script1.setAttribute('id', 'lite-youtube-embed')
        document.querySelector('head').appendChild(script1)


    }
}


function loadInfiniteScroll()
{
    if(document.querySelector(".post_wrapper") && document.querySelector('.read_more')){
        let page = 2;
        document.querySelector(".read_more").onclick = function (){
            try{
                document.querySelector(".loader").style.display = 'block';
                let link = new URL(document.querySelector('.read_more').getAttribute('data-ajax-link'));
                if(link.searchParams.size===0)
                    link = document.querySelector('.read_more').getAttribute('data-ajax-link')+"?page="+page;
                else{
                    link = document.querySelector('.read_more').getAttribute('data-ajax-link')+"&page="+page;
                }
                fetch(link).then(function (r){
                    return r.text()
                }).then(function (html){
                    let div = document.createElement("div");
                    div.innerHTML = html;
                    if(div.querySelectorAll(".post_item").length>0){
                        div.querySelectorAll(".post_item").forEach(function (postItem){
                            document.querySelector(".post_wrapper").appendChild(postItem);
                        })
                        imageLazyLoading(document.querySelectorAll('img[src="#"][loading=lazy]'));
                        page++;
                        document.querySelector(".loader").style.display = 'none';
                    }else{
                        document.querySelector(".read_more").style.display = "none";
                        document.querySelector(".loader").style.display = 'none';
                    }
                }).catch(function (e){
                    console.log(e);
                    document.querySelector(".loader").style.display = 'none';
                })
            }catch (e) {
                console.log(e)
            }


        }
    }
}
